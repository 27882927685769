export * from 'core/shared/utils'

// Convert base64/URLEncoded data component to raw binary data held in a string
export function dataURLtoBlob(dataurl: string): Blob {
  const arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)?.[1],
    bstr = atob(arr[1]),
    n = bstr.length
  const u8arr = new Uint8Array(n)

  for (let i = n; i--; ) {
    u8arr[i] = bstr.charCodeAt(i)
  }

  return new Blob([u8arr], { type: mime ?? 'application/octet-stream' })
}
