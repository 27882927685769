import { Routes as CoreRoutes } from 'core/pages'
import TagInfo from './TagInfo/TagInfo'
import CountCollo from './CountCollo/CountCollo'
import CountTBoxUbicazioniStart from 'core/pages/CountTBoxUbicazioni/CountTBoxUbicazioniStart'
import CountTBoxUbicazioniReading from 'core/pages/CountTBoxUbicazioni/CountTBoxUbicazioniReading'
import ImbustatriceStart from 'core/pages/Encoding/Imbustatrice/ImbustatriceStart'
import ImbustatriceEncode from 'core/pages/Encoding/Imbustatrice/ImbustatriceEncode'
import ConveyorStart from 'core/pages/Conveyor/ConveyorStart'
import ConveyorReading from 'core/pages/Conveyor/ConveyorReading'
import {
  InboundConveyor,
  InboundWorkstation,
  MassiveConveyor,
  OutboundConveyor,
  PrintWamScreen,
} from './Quality-Control/pages'

///////////////////////////////////////////////////////////////////////////////
// Custom & core overrides, change only this!
///////////////////////////////////////////////////////////////////////////////

const CustomDirectRoutes = {
  '/tag-info/CountCollo/:opCode': { component: CountCollo },
  '/transfer-to-zone/conveyor/:opCode': { component: ConveyorStart },
  '/transfer-to-zone/conveyor/:opCode/reading': { component: ConveyorReading },
  '/simple-item-session-list/countTBoxUbicazioni/:opCode': { component: CountTBoxUbicazioniStart },
  '/simple-item-session-list/countTBoxUbicazioni/:opCode/reading': { component: CountTBoxUbicazioniReading },
  '/encoding/imbustatrice/:opCode': { component: ImbustatriceStart },
  '/encoding/imbustatrice/:opCode/encode': { component: ImbustatriceEncode },
  '/tag-info/ingresso-varco/:opCode': { component: InboundConveyor },
  '/tag-info/postazione-singola/:opCode': { component: InboundWorkstation },
  '/tag-info/varco-massivo/:opCode': { component: MassiveConveyor },
  '/tag-info/varco-uscita/:opCode': { component: OutboundConveyor },
  '/tag-info/print-wam/:opCode': { component: PrintWamScreen },
}
const CustomRoutes = {
  '/tag-info/:opCode': { component: TagInfo },
}

///////////////////////////////////////////////////////////////////////////////

export const Routes = { ...CustomDirectRoutes, ...CoreRoutes, ...CustomRoutes }
// eslint-disable-next-line
export type Routes = keyof typeof Routes
