import { TmrBaseResource, api, responseErrorCheck } from 'stylewhere/api'
import { MassiveConveyorItemsConfirmation } from './types'

export class MassiveConveyor extends TmrBaseResource {
  static endpoint = ''

  static createSapQualityControlItems(data: MassiveConveyorItemsConfirmation) {
    return api.post<any>(`sapQualityControl/sapInbounds/postazione/ingresso/cq/massivo`, data).then(responseErrorCheck)
  }
}
