import { DecodedItem, ProductInfoField } from 'stylewhere/api'
import { __, T } from 'stylewhere/shared/i18n'
export * from 'core/shared/utils'

export const getSimulateProductCode = () => {
  return '8052962085429'
}

export const getSimulateEpcs = () => {
  return []
}

export const getConveyorItemConfiguration = () => {
  return { useProductCode: true, showProductImage: false, hideIdentifiers: true, hideCqStatus: true }
}

export const sortGroupeditemList = (a: DecodedItem) => {
  if (a.item && a.item.id) return 1
  return -1
}

export const sortGroupeditemListStatus = (a, b) => {
  if (a.item && a.item.status && a.item.status === 'error') return -1
  if (b.item && b.item.status && b.item.status === 'error') return 1
  if (a.item && a.item.status && a.item.status === 'warning') return -1
  if (b.item && b.item.status && b.item.status === 'warning') return 1
  if (a.item && a.item.status && a.item.status === 'ignored') return -1
  if (b.item && b.item.status && b.item.status === 'ignored') return 1
  return 0
}

export const getImbustatriceProductError = (product) => {
  let str = ''
  if (product) {
    str =
      product.attributes && product.attributes.wam
        ? product.attributes.wam.value || product.attributes.wam
        : product.code
    if (product.material && product.material.value) str += ' / ' + product.material.value
    if (product.size && product.size.value) str += ' / ' + product.size.value
    if (product.color && product.color.value) str += ' / ' + product.color.value
    str += ' / '
  }
  return str
}

export const isQcStatusProduct = (item) => {
  let cqStatus = 'KO'
  if (item && item.attributes && item.attributes.qcStatus) {
    cqStatus =
      item.attributes.qcStatus == 'KO_WORKER' ||
      item.attributes.qcStatus == 'KO_VALDAGNO' ||
      item.attributes.qcStatus == 'KO_LINING' ||
      item.attributes.qcStatus == 'LOADING'
        ? 'KO'
        : 'OK'
  }
  return cqStatus === 'OK'
}

export const checkQcStatus = () => {
  return true
}

export const getProductCode = (product) => {
  let str = ''
  if (product) {
    str =
      product.attributes && product.attributes.wam
        ? product.attributes.wam.value || product.attributes.wam
        : product.product
        ? product.product.code
        : product.code
  }
  return str
}

export const enabledWamGroupedProduct = () => {
  return true
}

export const getEncodingProductFields = () => {
  const productFields: ProductInfoField[] = [
    { path: 'material.value', label: __(T.misc.material) },
    { path: 'size.value', label: __(T.misc.size) },
    { path: 'color.value', label: __(T.misc.color) },
  ]
  return productFields
}

export const enabledDisassociateItemTag = () => {
  return false
}

export const enabledUtilities = () => {
  return false
}

export const getInitialType = (operation) => {
  return operation.options && operation.options.initialType ? operation.options.initialType : 'product'
}
