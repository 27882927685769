import styled from '@emotion/styled'
import { Box, Text, Image, Icons, Button } from 'stylewhere/components'
import { T, __ } from 'stylewhere/i18n'
import { showToast } from 'stylewhere/utils'
import { AppStore, Router } from 'stylewhere/shared'

type ButtonProps = {
  code: string
  operation: string
  routeParams?: any
  icon?: React.ReactNode
  text?: string
  displayPriority: number
  disableWorkstationCheck: boolean
}

interface Props {
  refreshCounter: number
}

export const DashboardOperationsButtons: React.FC<Props> = () => {
  const onClick = (route, routeParams, disableWorkstationCheck) => {
    if (!AppStore.defaultWorkstation && !disableWorkstationCheck) {
      showToast({
        sound: false,
        title: __(T.error.error),
        description: __(T.messages.no_workstation_selected),
        status: 'error',
      })
      if (!AppStore.emulation) return
    }
    route && Router.navigate(route, routeParams)
  }

  const refresh = () => {
    window.location.reload()
  }

  const staticButtons = [
    {
      code: 'inbound-conveyor',
      operation: '/inbound-conveyor',
      icon: <Icons.Inbound />,
      routeParams: undefined,
      text: 'Ingresso Varco CQ',
      displayPriority: 0,
      disableWorkstationCheck: false,
    },
    {
      code: 'inbound-worksatation',
      operation: '/inbound-workstation',
      icon: <Icons.Inbound />,
      routeParams: undefined,
      text: 'Postazione singola CQ',
      displayPriority: 0,
      disableWorkstationCheck: false,
    },
    {
      code: 'massive-conveyor-cq',
      operation: '/massive-conveyor',
      icon: <Icons.Inbound />,
      routeParams: undefined,
      text: 'Varco Massivo CQ',
      displayPriority: 0,
      disableWorkstationCheck: false,
    },
    {
      code: 'outbound-conveyor',
      operation: '/outbound-conveyor',
      icon: <Icons.Outbound />,
      routeParams: undefined,
      text: 'Varco uscita CQ',
      displayPriority: 0,
      disableWorkstationCheck: false,
    },
    {
      code: 'print-wam',
      operation: '/print-wam',
      icon: <Icons.Print />,
      routeParams: undefined,
      text: 'Print WAM',
      displayPriority: 0,
      disableWorkstationCheck: false,
    },
  ]

  return (
    <>
      {staticButtons.length === 0 && (
        <Box flex center mb={50} height={'calc(100% - 50px)'}>
          <Text fontSize={20}>{__(T.messages.no_operations_found)}</Text>
          <Button style={{ marginTop: 20 }} variant="primary" type="button" onClick={() => refresh()}>
            {__(T.misc.refresh)}
          </Button>
        </Box>
      )}
      {staticButtons.length > 0 && (
        <DashView>
          {staticButtons.map(({ operation: route, routeParams, icon, text, code, disableWorkstationCheck }) => (
            <ButtonDashboard key={code} onClick={() => onClick(route, routeParams, disableWorkstationCheck)}>
              {icon && typeof icon !== 'string' && icon}
              {icon && typeof icon === 'string' && <Icon alt={text} src={icon} />}
              {text}
            </ButtonDashboard>
          ))}
          {staticButtons.length % 2 === 1 && <FakeButton />}
        </DashView>
      )}
    </>
  )
}

const DashView = styled(Box)`
  margin-right: 20px;
  justify-content: center;
  flex: 1;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: start;
  padding-top: 30px;
  flex-basis: 150px;
  flex-shrink: 0;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
`

const ButtonDashboard = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  min-width: 276px;
  flex: 1;
  height: 202px;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0 1px 5px 0 #00000014;
  border-radius: 10px;
  margin: 0 30px 30px 0px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
`

const FakeButton = styled.div`
  min-width: 276px;
  flex: 1;
  height: 202px;
  background: #ffffff;
  border: 2px dashed #eeeeee;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 0 30px 30px 0px;
  padding: 0 20px;
`

const Icon = styled(Image)`
  margin-bottom: 10px;
  max-height: 100px;
  max-width: 100px;
`
