import { ProductInfoBox as CoreProductInfoBox } from 'core/components'
import { TmrProduct } from 'stylewhere/api'

export class ProductInfoBox extends CoreProductInfoBox {
  getProductCode(product?: TmrProduct): string {
    if (product && product.attributes && product.attributes.wam) {
      return product.attributes.wam.value
        ? product.attributes.wam.value
        : product.attributes.wam
        ? product.attributes.wam
        : '---'
    }
    return product?.code ?? '---'
  }
}
