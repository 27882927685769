import { Component } from 'react'
import { Page, GroupedItemList, TagInfoBox } from 'stylewhere/components'
import { DecodedItem, ItemEvents, TagInfoItem } from 'stylewhere/api'
import { Router, RemoteOperation, RfidReader, OperationReadingProps, OperationReadingProvider } from 'stylewhere/shared'
import { TagInfoOperationConfig } from 'stylewhere/shared/RemoteOperation'
import { checkItemDecodedStatuses } from 'stylewhere/utils'

interface State {
  items: DecodedItem<string>[]
  detected: number
}

export default class TagInfo extends Component<OperationReadingProps<State>, State> {
  useSupplier = true
  locationState = Router.getLocationState<State>(this.props)
  operation = RemoteOperation.getOperationConfig<TagInfoOperationConfig>(Router.getMatchParams(this.props).opCode)

  state: State = {
    items: [],
    detected: 0,
  }

  componentDidMount() {
    RfidReader.setBatchInterval(this.operation.batchInterval)
    RfidReader.setBatchIntervalTagCount(this.operation.batchIntervalTagCount)
    RfidReader.setBatchIntervalTime(this.operation.batchIntervalTime)
    RfidReader.setAutomaticStop(this.operation.autostopAntennaTimeout > 0)
    RfidReader.setAutomaticStopTime(this.operation.autostopAntennaTimeout)
    OperationReadingProvider.init(this.operation, this.locationState, this.goBack, this.setRfidReaderDecode, true)
  }

  getDecodeRequest = () => {
    return {
      url: TagInfoItem.batchValidateEndpoint(),
      payload: {
        operationId: this.operation.id,
        fetchReplaceIdentifier: false,
      },
    }
  }

  setRfidReaderDecode = () => {
    RfidReader.setOnDecodedItemCallback(this.onItemDecoded, this.getDecodeRequest())
  }

  onItemDecoded = async (decodedItems: { [epc: string]: DecodedItem<string> }) => {
    let itemsDetected = this.state.detected
    const tmpItems: DecodedItem<string>[] = this.state.items
    const keys = Object.keys(decodedItems)
    if (keys.length > 0) {
      let tmpItem
      keys.map((key) => {
        const decodedItem = decodedItems[key]
        if (decodedItem && decodedItem !== null) {
          const statusesOk = checkItemDecodedStatuses(decodedItem.statuses || [], ['OK', 'SGTIN_FALLBACK_ITEM_CREATED'])
          if (statusesOk && decodedItem.item && decodedItem.item !== null && decodedItem.item.id) {
            tmpItem = (decodedItem.item || decodedItem) as any
            if (!tmpItems.find((itm) => itm.item !== null && itm.item?.id === tmpItem.id)) {
              tmpItems.push({
                item: tmpItem,
                identifierCode: key,
              })
              itemsDetected++
            }
          }
        }
      })
      //si prendono i itemEvents per determinare il supplier dell'item
      if (this.useSupplier) {
        await Promise.all(
          tmpItems.map(async (itm) => {
            if (itm.item && itm.item.id) {
              itm.item.supplier = await this.getItemSupplier(itm.item.id)
            }
          })
        )
      }
      this.setState({ items: tmpItems, detected: itemsDetected })
    }
  }

  getItemSupplier = async (id) => {
    let supplier = undefined
    let events: any = { content: [] }
    try {
      events = await ItemEvents.get(id, {
        size: 100,
        sort: 'creationDate,DESC',
      })
    } catch (e) {}
    if (events && events.content) {
      const event = events.content.find((ev) => ev.eventType === 'ITEM_CREATED')
      if (event && event.user && event.user.userPlaces && event.user.userPlaces.length > 0) {
        supplier = event.user.userPlaces[0].description
      }
    }
    return supplier
  }

  clear = () => this.setState({ items: [], detected: 0 })

  goBack = () => {
    Router.navigate('/')
  }

  render() {
    const { items, detected } = this.state
    return (
      <Page title={this.operation.description} enableEmulation>
        <Page.Sidebar>
          <TagInfoBox
            decodeRequest={this.getDecodeRequest()}
            onItemDecoded={this.onItemDecoded}
            items={items}
            detected={detected}
            clear={this.clear}
          />
        </Page.Sidebar>
        <Page.Content notBoxed>
          <GroupedItemList rows={items} />
        </Page.Content>
      </Page>
    )
  }
}
