import { api, responseErrorCheck } from '.'
import { TmrBaseResource } from './TmrBaseResource'
import { InboundConveyor, PrintDataInboundConveyor } from './types'

export class CqImageApi extends TmrBaseResource {
  static endpoint = 'qualityControl/images/download/urls'

  static async getImages(images: string[]) {
    let params = ''
    // eslint-disable-next-line no-return-assign
    images.forEach((image: string) => (params += `imageNames=${image}&`))
    return api.get(`${this.endpoint}/?${params}`).then((res: any) => res.data)
  }
}
