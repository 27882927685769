import { api, responseErrorCheck } from '.'
import { TmrBaseResource } from './TmrBaseResource'
import { ItemHistory, PrintDataInboundConveyor } from './types'

export class PrintWam extends TmrBaseResource {
  static async getItemHistory(identifierCode: string) {
    return api.get(`qualityControl/items/tagHistory/${identifierCode}`, undefined).then(responseErrorCheck)
  }

  static decodeItem(epc: string) {
    return api.get(`tracking/api/v1/items/decode/${epc}`).then(responseErrorCheck)
  }

  static async print(data: PrintDataInboundConveyor) {
    return api.post('sapQualityControl/sapInbounds/varco/ingresso/print', data).then(responseErrorCheck)
  }
}
