import { api, responseErrorCheck } from '.'
import { TmrBaseResource } from './TmrBaseResource'
import { OutboundConveyorCheckReadingsPayload, OutboundConveyorResponseDto } from './types'

export class OutboundConveyor extends TmrBaseResource {
  static endpoint = 'sapQualityControl/sapOutbounds'

  static verifyReads(data: OutboundConveyorCheckReadingsPayload) {
    return api.post(this.endpoint + '/uscita/merce/check', data).then(responseErrorCheck)
  }

  static confirmReads(data: OutboundConveyorResponseDto) {
    return api.post(this.endpoint + '/uscita/merce/confirm', data).then(responseErrorCheck)
  }

  static decodeItem(epc: string) {
    return api.get(`tracking/api/v1/items/decode/${epc}`).then(responseErrorCheck)
  }
}
