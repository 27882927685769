import { Component } from 'react'
import styled from '@emotion/styled'
import { Box, RoundedLabel } from 'stylewhere/components'
import { TmrProduct } from 'stylewhere/api'
import { getProductCode } from 'stylewhere/shared/utils'
import { __, T } from 'stylewhere/shared/i18n'

interface Props {
  product: TmrProduct
}

export class GroupedProductRowInfo extends Component<Props> {
  render() {
    const { product } = this.props
    const productSizeColor =
      product &&
      product.attributes &&
      product.attributes.tagliaColore &&
      product.attributes.tagliaColore.value &&
      product.attributes.tagliaColore.value !== ''
        ? product.attributes.tagliaColore.value
        : 'N/A'
    return (
      <Box flex ph={16} style={{ alignSelf: 'center' }}>
        <Box mb={product && product.id ? 10 : 0}>
          <ProductCode>{getProductCode(product)}</ProductCode>
          {product.description && <ProductDescription>{product.description}</ProductDescription>}
        </Box>
        {product && product.id && (
          <Box row style={{ justifyContent: 'flex-start', flexWrap: 'wrap' }}>
            <RoundedLabel label={__(T.misc.material)} value={product.material?.value || 'N/A'} />
            {productSizeColor ? (
              <RoundedLabel label={__(T.misc.size) + ' - ' + __(T.misc.color)} value={productSizeColor} />
            ) : (
              <>
                <RoundedLabel label={__(T.misc.size)} value={product.size?.value || 'N/A'} />
                <RoundedLabel label={__(T.misc.color)} value={product.color?.value || 'N/A'} />
              </>
            )}
          </Box>
        )}
      </Box>
    )
  }
}

const ProductDescription = styled.label`
  font-size: 15px;
  word-break: break-word;
`
const ProductCode = styled.label`
  font-weight: bold;
  font-size: 20pt;
  word-break: break-word;
`
