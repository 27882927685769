import { T, __ } from 'stylewhere/shared/i18n'
import { Box, Button, FormSchemaTextField, Icons, KeyValueRow, Spacer, Table } from '.'
import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { showToastError } from 'stylewhere/shared/utils'
import { Defect, DefectsTable, SapInboundItem, SizeAndColorPropery } from '../api/types'
import { InboundWorkstationApi } from '../api'

interface Props {
  item?: SapInboundItem
  readItems: SapInboundItem[]
  type: 'single' | 'massive'
  onParentDefectSelected: (defect: Defect) => void
  onChildDefectSelected: (defect: Defect) => void
  clearSelectedDefects: () => void
  currentSelectedMerch?: string
  updateAddedDefects?: (defects: DefectsTable[]) => void
  onMerchCateogrySelected: (cat: { value: string; label: string }) => void
  merchCategories: string[]
  parentDefects?: Defect[]
  childDefects?: Defect[]
  selectedCatMerch?: { value: string; label: string }
  onSizeAndColorSelected: (sizeAndColor: SizeAndColorPropery) => void
  onNotesChange: (notes: string) => void
  onControlTypeChange: (controlType: ControlType) => void
  onControlStatusChange: (controlStatus: ControlStatus) => void
  toggleClearComponent: boolean
}

type ControlType = 'FIRST' | 'SECOND'
type ControlStatus = 'OK_VALDAGNO' | 'KO_VALDAGNO' | 'OK_RISC' | undefined

export default function QualityControlPanelMassive({
  item,
  type,
  currentSelectedMerch,
  onChildDefectSelected,
  onParentDefectSelected,
  clearSelectedDefects,
  updateAddedDefects,
  onMerchCateogrySelected,
  merchCategories,
  parentDefects,
  childDefects,
  selectedCatMerch,
  readItems,
  onControlTypeChange,
  onNotesChange,
  onControlStatusChange,
  onSizeAndColorSelected,
  toggleClearComponent,
}: Props) {
  const [control_type, setControlType] = useState<ControlType>('FIRST')
  const [control_status, setControlStatus] = useState<ControlStatus>(undefined)
  const [selected_parent_cause, setSelectedParentCause] = useState<any>(undefined)
  const [selected_cause, setSelectedCause] = useState<any>(undefined)
  const [selectedSizeAndColor, setSelectedSizeAndColors] = useState<any>(undefined)
  const [sizesAndColors, setSizesAndColors] = useState<SizeAndColorPropery[]>([])
  const [notes, setNotes] = useState<string>('')
  const [addedDefects, setAddedDefects] = useState<{ id: string; parent: Defect; child: Defect }[]>([])

  const causeTableStructure = [
    {
      key: 'macrofamily',
      label: 'Macrofamiglia',
      customRender: (cause: DefectsTable, column) => <Box center>{cause.parent!.description}</Box>,
      width: 120,
    },
    {
      key: 'code',
      label: 'Codice',
      customRender: (cause: DefectsTable, column) => <Box center>{cause.child.code}</Box>,
      flex: 1,
    },
    {
      key: 'causal',
      label: 'Causale',
      customRender: (cause: DefectsTable, column) => <Box center>{cause.child.description}</Box>,
      width: 150,
    },
    {
      key: 'delete',
      label: 'Elimina',
      customRender: (cause, column) => (
        <Box center>
          <Icons.Delete onClick={() => deleteCause(cause)} />
        </Box>
      ),
      width: 86,
    },
  ]

  const setControlTypeChange = (controlType: ControlType) => {
    setControlType(controlType)
    setNotes('')
    setControlStatus(undefined)
    onControlTypeChange && onControlTypeChange(controlType)
  }

  const deleteCause = (cause: any) => {
    const newAddedDefects = addedDefects.filter((d) => d.id !== cause.id)
    setAddedDefects(newAddedDefects)
    updateAddedDefects && updateAddedDefects(newAddedDefects)
  }

  const addDefects = () => {
    try {
      const isDefectAlreadyAdded = addedDefects.filter((d) => d.id === selected_cause.value.id).length > 0
      if (isDefectAlreadyAdded) return showToastError('Causale già aggiunta')
      if (addedDefects.length === 5) throw new Error("E' possibile aggiungere massimo 5 difetti")
      if (selected_parent_cause && selected_cause) {
        const newAddedDefects = [
          ...addedDefects,
          { id: selected_cause.value.id, parent: selected_parent_cause.value, child: selected_cause.value },
        ]
        setAddedDefects(newAddedDefects)
        setSelectedParentCause(undefined)
        setSelectedCause(undefined)
        clearSelectedDefects()
        updateAddedDefects && updateAddedDefects(newAddedDefects)
      }
    } catch (error: any) {
      showToastError(error.message || 'Errore aggiunta causale')
    }
  }

  const changeControlStatus = (controlStatus: ControlStatus) => {
    setControlStatus(controlStatus)
    onControlStatusChange && onControlStatusChange(controlStatus)
  }

  const sizeColorChange = (sizeAndColor: SizeAndColorPropery) => {
    setSelectedSizeAndColors(sizeAndColor)
    onSizeAndColorSelected && onSizeAndColorSelected(sizeAndColor)
  }

  useEffect(() => {
    setSelectedParentCause(undefined)
    setSelectedCause(undefined)
  }, [currentSelectedMerch])

  useEffect(() => {
    async function fetchSizeAndColor() {
      try {
        if (!item) return
        const material = item?.matNr
        if (!material) throw new Error('Material of the item not found')
        if (!material) return
        const res = await InboundWorkstationApi.getSizeColorByMaterial(material)
        if (!res || !res.content || res.content.length === 0) return
        setSizesAndColors(res.content)
      } catch (error: any) {
        showToastError(error.message || 'Errore recupero taglie e colori')
      }
    }
    fetchSizeAndColor()
    setAddedDefects([])
    updateAddedDefects && updateAddedDefects([])
    setNotes('')
    setSelectedParentCause(undefined)
    setSelectedCause(undefined)
  }, [item, updateAddedDefects])

  useEffect(() => {
    setControlStatus(undefined)
    setNotes('')
    setSelectedSizeAndColors(undefined)
    setControlType('FIRST')
    setSelectedParentCause(undefined)
    setSelectedCause(undefined)
  }, [toggleClearComponent])

  return (
    <Box row mt={20} style={{ gap: 10 }}>
      <Box flex>
        <Box row center mt={15}>
          <Button
            title={'I° Controllo'}
            variant={control_type === 'FIRST' ? 'primary' : 'ignore'}
            onClick={() => setControlTypeChange('FIRST')}
          />
          <Spacer width={15} />
          <Button
            title={'II° Controllo'}
            variant={control_type === 'SECOND' ? 'primary' : 'ignore'}
            onClick={() => setControlTypeChange('SECOND')}
          />
        </Box>
        <Box row center justify="space-between" mt={27}>
          <ControlStatusBox
            active={control_status === 'OK_VALDAGNO'}
            backgroundColor={'#1fd655'}
            onClick={() => changeControlStatus('OK_VALDAGNO')}
          >
            OK
          </ControlStatusBox>
          <ControlStatusBox
            active={control_status === 'KO_VALDAGNO'}
            backgroundColor={'#CD5642'}
            onClick={() => changeControlStatus('KO_VALDAGNO')}
          >
            {' '}
            KO{' '}
          </ControlStatusBox>
          <ControlStatusBox
            active={control_status === 'OK_RISC'}
            backgroundColor={'#E7A03C'}
            onClick={() => changeControlStatus('OK_RISC')}
          >
            {' '}
            RISC
          </ControlStatusBox>
        </Box>
        <Box row center justify="space-between" mt={15} mb={15}>
          <KeyValueRow
            label={__(T.misc.size_color)}
            value={selectedSizeAndColor?.label}
            style={{ flex: 1 }}
            optionModal={{
              onSelect: (sizeColor) => sizeColorChange(sizeColor[0]),
              field: 'label',
              options:
                sizesAndColors.map((s) => {
                  return { value: s.value, label: s.value }
                }) || [],
              selected: [selectedSizeAndColor],
              title: __(T.misc.select_size_color),
              searchable: true,
            }}
          />
        </Box>
        <Box mt={15} ph={15}>
          <FormSchemaTextField
            defaultValue={undefined}
            field={{ type: 'text', label: __(T.misc.note), name: 'Notes', focus: false }}
            index={9}
            value={notes}
            onChange={(text: string) => {
              setNotes(text)
              onNotesChange && onNotesChange(text)
            }}
          />
        </Box>
      </Box>
      <Box flex>
        <Box row mb={15}>
          <KeyValueRow
            label={__(T.fields.product_category)}
            style={{ flex: 1 }}
            value={selectedCatMerch?.label}
            optionModal={{
              onSelect: (cat) => onMerchCateogrySelected(cat[0]),
              field: 'label',
              options: merchCategories.map((cat) => ({ label: cat, value: cat })),
              selected: [selectedCatMerch],
              title: __(T.fields.product_category),
              searchable: true,
            }}
          />
          <Spacer width={15} />
          <KeyValueRow
            label={'Parent Cause Label'}
            style={{ flex: 1 }}
            value={selected_parent_cause?.label}
            optionModal={{
              onSelect: (cat) => {
                setSelectedParentCause(cat[0])
                onParentDefectSelected && onParentDefectSelected(cat[0].value)
              },
              field: 'label',
              options: parentDefects?.map((d) => ({ value: d, label: d.description })) || [],
              selected: [selected_parent_cause],
              title: 'Parent Cause Label',
              searchable: true,
            }}
          />
          <Spacer width={15} />
          <KeyValueRow
            label={'Cause'}
            style={{ flex: 1 }}
            value={selected_cause?.label}
            optionModal={{
              onSelect: (cat) => {
                setSelectedCause(cat[0])
                onChildDefectSelected && onChildDefectSelected(cat[0].value)
              },
              field: 'label',
              options: childDefects?.map((d) => ({ value: d, label: d.description })) || [],
              selected: [selected_cause],
              title: 'Cause',
              searchable: true,
            }}
          />
        </Box>
        <Spacer height="15" />
        <Box style={{ alignItems: 'flex-end' }}>
          <Button
            disabled={!selected_parent_cause || !selected_cause}
            title="Aggiungi Causale"
            variant="default"
            onClick={addDefects}
          />
        </Box>
        <Spacer height="15" />
        <Table data={addedDefects} structure={causeTableStructure} />
      </Box>
    </Box>
  )
}

const ControlStatusBox = styled(Box)<{ active?: boolean; backgroundColor?: string }>`
  height: 80px;
  width: 150px;
  background-color: ${(props) => props.backgroundColor || '#e2e2e2'};
  border: ${(props) => (props.active ? '5px solid #3091ff' : 'none')};
  color: white;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
`

const Label = styled.span`
  font-size: 20px;
`

const Value = styled.span`
  font-weight: bold;
  font-size: 20px;
`
