import styled from '@emotion/styled'

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  margin-bottom: 20px;
`
