//import { AppConfiguration } from 'stylewhere/types'

export * from 'core/config'

/*
export const config: AppConfiguration = {
  endpoint: process.env.REACT_APP_BACKEND_URL ?? 'https://api.valentino-stw-dev.temera.it/',
  keycloakUrl: process.env.REACT_APP_KEYCLOAK_URL ?? 'https://sso-dev.temera.it/',
  keycloakRealm: process.env.REACT_APP_KEYCLOAK_REALM ?? 'valentino-dev',
  keycloakClientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? 'stw-core-station',
}
*/
