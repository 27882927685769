import { Component } from 'react'
import styled from '@emotion/styled'
import { Box } from 'stylewhere/components'
import { T, __ } from 'stylewhere/i18n'
import { getProductCode } from 'stylewhere/shared/utils'

interface Props {
  itemList: any[]
  viewHeader?: boolean
}

export class EncodingInboundSapViewItemList extends Component<Props> {
  getLabel = (product) => {
    let label = ''
    if (product) {
      if (product.material)
        label +=
          (label !== '' ? ' / ' : '') +
          (product.material.description && product.material.description != null
            ? product.material.description
            : product.material.value)
      if (
        product.attributes &&
        product.attributes &&
        product.attributes.tagliaColore &&
        product.attributes.tagliaColore.value &&
        product.attributes.tagliaColore.value !== ''
      )
        label += (label !== '' ? ' / ' : '') + product.attributes.tagliaColore.value
      else {
        if (product.color)
          label +=
            (label !== '' ? ' / ' : '') +
            (product.color.description && product.color.description != null
              ? product.color.description
              : product.color.value)
        if (product.size)
          label +=
            (label !== '' ? ' / ' : '') +
            (product.size.description && product.size.description != null
              ? product.size.description
              : product.size.value)
      }
    }
    return label !== '' ? label : '---'
  }

  render() {
    const { itemList, viewHeader } = this.props
    return (
      <Box flex mb={15}>
        {viewHeader && (
          <IntestazioneRow row ml={15} mr={15}>
            <Intestazione width={320}>{__(T.custom.WAM)}</Intestazione>
            <Intestazione flex>
              {__(T.misc.material)} / {__(T.misc.size)} - {__(T.misc.color)}
            </Intestazione>
            <Intestazione width={160} noBorder>
              {__(T.misc.only_quantity)}
            </Intestazione>
          </IntestazioneRow>
        )}
        {itemList.map((item, index) => {
          return (
            <ItemRow key={'item-list-' + index} row ml={15} mr={15} last={index === itemList.length - 1}>
              <ItemColumn width={320}>{getProductCode(item)}</ItemColumn>
              <ItemColumn flex>{this.getLabel(item.product)}</ItemColumn>
              <ItemColumn width={160} noBorder>
                {item.quantity || 1}
              </ItemColumn>
            </ItemRow>
          )
        })}
      </Box>
    )
  }
}

const IntestazioneRow = styled(Box)`
  border-radius: 10px 10px 0px 0px;
  background-color: #f6f6f6;
  overflow: hidden;
`

const Intestazione = styled(Box)<{ noBorder?: boolean }>`
  height: 65px;
  padding: 0px 15px;
  color: #000000;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  justify-content: center;
  border-right: ${(props) => (props.noBorder ? '0px' : '1px solid #F1F1F1')};
`

const ItemRow = styled(Box)<{ last?: boolean }>`
  border: 1px solid #f1f1f1;
  ${(props) => (props.last ? 'border-radius: 0px 0px 10px 10px' : '')}
`

const ItemColumn = styled(Box)<{ noBorder?: boolean }>`
  height: 65px;
  padding: 0px 15px;
  color: #000000;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  justify-content: center;
  border-right: ${(props) => (props.noBorder ? '0px' : '1px solid #F1F1F1')};
`
