import { Component } from 'react'
import {
  AntennaButton,
  Box,
  Button,
  FormSchemaNumberField,
  FormSchemaTextField,
  KeyValueRow,
  Modal,
  Page,
  Spacer,
  Table,
} from 'stylewhere/components'
import { Router, OperationReadingProps, Storage, RfidReader } from 'stylewhere/shared'
import styled from '@emotion/styled'
import { T, __ } from 'stylewhere/shared/i18n'
import { __isDev, askUserConfirmation, showToast, showToastError } from 'stylewhere/shared/utils'
import { EnumerationValues, Items } from 'stylewhere/api'
import { PrintDataInboundConveyor } from '../../api/types'
import { Counter } from '../../components'
import { PrintWamApi } from '../../api'

type Printer = {
  value: string
  label: string
}

interface State {
  printLayout: string
  ignoredItems: any[]
  showPrintModal: boolean
  selectedPrinter?: Printer
  printLoading: boolean
  printers?: Printer[]
  readItems: any[]
}

export default class PrintWamScreen extends Component<OperationReadingProps<State>, State> {
  locationState = Router.getLocationState<State>(this.props)

  state: State = {
    showPrintModal: false,
    printLayout: '',
    printLoading: false,
    ignoredItems: [],
    readItems: [],
  }

  missionRef: any = null

  tableStructure = [
    {
      key: 'wam',
      label: 'WAM',
      customRender: (item, column) => <Box center>{item.wam ?? ''}</Box>,
      flex: 1,
    },
    {
      key: 'model',
      label: 'Modello',
      customRender: (item, column) => <Box center>{item.material ?? ''}</Box>,
      flex: 1,
    },
    {
      key: 'size',
      label: 'Taglia/Colore',
      customRender: (item, column) => <Box center>{item.sizeColor ?? ''}</Box>,
      flex: 1,
    },
    {
      key: 'commessa',
      label: 'Commessa',
      customRender: (item, column) => <Box center>{item.addonDetail?.commessaNumber}</Box>,
      flex: 1,
    },
    {
      key: 'readQty',
      label: 'Qta Letta',
      flex: 1,
      customRender: (item, column) => {
        return (
          <FormSchemaNumberField
            defaultValue={item.printQty ?? 0}
            field={{
              type: 'number',
              label: '',
              name: '', //'Totale Rientrati',
              focus: false,
              min: 0,
            }}
            index={0}
            value={item.printQty ?? 0}
            onChange={(text: string) => {
              if (text === '') text = '0'
              this.setState({
                readItems: this.state.readItems.map((i) => {
                  if (i.wam === item.wam) {
                    i.printQty = parseInt(text ?? 0)
                  }
                  return i
                }),
              })
            }}
          />
        )
      },
    },
    {
      key: 'upc',
      label: 'Upc',
      customRender: (item, column) => <Box center>{item?.upc ?? ''}</Box>,
      flex: 1,
    },
    {
      key: 'addon',
      label: 'Addon',
      customRender: (item, column) => <Box center>{item?.addonDetail?.addonCode ?? ''}</Box>,
      flex: 1,
    },
  ]

  async componentDidMount() {
    this.initReader()
    const printers = await this.fetchPrinters()
    this.loadLayout()
    const selectedPrinter = (await Storage.load('cq-selectedPrinter')) as Printer
    if (!selectedPrinter || !printers.find((printer) => printer.value === selectedPrinter.value)) return
    this.setState({ selectedPrinter })
  }

  fetchPrinters = async () => {
    try {
      const res = (await EnumerationValues.search({ enumerationTypeCode: 'printer-sap' })) as any
      if (!res || !res.content) throw new Error('No printers')
      const printers = res.content.map((printer: any) => ({ value: printer.code, label: printer.description }))
      this.setState({ printers })
      return printers
    } catch (error: any) {
      showToastError(error?.message ?? __(T.misc.error))
    }
  }

  loadLayout = async () => {
    const loadedLayout = await Storage.load('cq-printLayout')
    if (loadedLayout) this.setState({ printLayout: loadedLayout })
    else this.setState({ printLayout: 'ZV_ETICHETTE_MIS_RFID' })
  }

  clear = () => this.setState({})

  goBack = () => {
    Router.navigate('/')
  }

  initReader = async () => {
    RfidReader.automaticStop = false
    RfidReader.setOnTagReadCallback((tag) => this.onTagReceived(tag.epc))
  }

  onTagReceived = async (tag: string) => {
    try {
      const item: any = await PrintWamApi.decodeItem(tag)
      if (!item || !item?.product?.code) {
        return this.setState((prevState) => {
          return { ignoredItems: [...prevState.ignoredItems, tag] }
        })
      }

      const itemHistory: any = ((await PrintWamApi.getItemHistory(tag)) as any)?.payload
      if (!itemHistory) throw new Error('No item history found')
      if (!itemHistory.addon && !itemHistory.wam) throw new Error('Codice Addon non trovato')
      const newItem = { ...itemHistory }
      const wam = itemHistory.wam ?? itemHistory.upc + itemHistory.addon

      return this.setState((prevState) => {
        const itemFoundIndex = prevState?.readItems?.findIndex((i) => i.wam === wam)
        if (itemFoundIndex !== -1) {
          prevState.readItems[itemFoundIndex].printQty += 1
          return { readItems: [...prevState.readItems] }
        } else {
          newItem.printQty = 1
          return { readItems: [...prevState.readItems, newItem] }
        }
      })
    } catch (error: any) {
      this.setState({ ignoredItems: [...this.state.ignoredItems, tag] })
      showToastError(error?.message ?? __(T.misc.error))
    }
  }

  onPrinterSelected = (printer: Printer) => {
    this.setState({ selectedPrinter: printer })
    Storage.save('cq-selectedPrinter', printer)
  }

  clearReads = async () => {
    const res = await askUserConfirmation(__(T.misc.confirm), __(T.messages.are_you_sure_to_clear_reads))
    if (!res) return
    RfidReader.clear()
    this.setState({ ignoredItems: [], readItems: [] })
  }

  print = async () => {
    try {
      const { selectedPrinter, readItems, printLayout } = this.state
      if (!selectedPrinter) throw new Error('Seleziona una stampante')
      if (!printLayout) throw new Error('Inserisci un layout')
      if (readItems.length === 0) throw new Error('Nessun articolo da stampare')
      this.setState({ printLoading: true })

      const postData: PrintDataInboundConveyor = {
        printer: selectedPrinter.value,
        layout: printLayout,
        wams: [],
      }

      readItems.forEach((item) => {
        postData.wams.push({
          commessa: item?.addonDetail?.commessaNumber,
          material: item?.material,
          sizeColor: item?.sizeColor,
          stockCategory: '',
          wam: item?.wam,
          qty: item?.printQty,
        })
      })

      await PrintWamApi.print(postData)
      this.setState({ printLoading: false, showPrintModal: false })
      showToast({
        title: __(T.misc.success),
        description: 'Stampa avviata',
        status: 'success',
      })
    } catch (error: any) {
      this.setState({ printLoading: false })
      showToastError(error?.message ?? __(T.misc.error))
    }
  }

  saveLayout = async () => {
    const { printLayout } = this.state
    Storage.save('cq-printLayout', printLayout)
    showToast({
      title: __(T.misc.success),
      description: 'Layout Salvato',
      status: 'success',
    })
  }

  onPrintPress = async () => {
    const { selectedPrinter, printLayout } = this.state
    if (!selectedPrinter) {
      showToastError('Seleziona una stampante')
      return
    }
    if (!printLayout || printLayout === '') {
      showToastError('Inserisci un layout')
      return
    }
    this.setState({ showPrintModal: true })
  }

  render() {
    const { ignoredItems, showPrintModal, selectedPrinter, printLoading, printers, readItems, printLayout } = this.state
    const hideClear = (readItems?.length ?? 0) + (ignoredItems?.length ?? 0) === 0
    console.log('readItems', readItems)
    return (
      <Page title={'Print Wam'} enableEmulation>
        <Page.Content notBoxed>
          <InfoContainer>
            <Box row vcenter style={{ gap: 20 }}>
              <KeyValueRow
                style={{ flex: 1 }}
                label={__(T.misc.select_printer)}
                value={selectedPrinter?.label}
                optionModal={{
                  onSelect: (printer) => this.onPrinterSelected(printer[0]),
                  field: 'label',
                  options: printers || [],
                  selected: [selectedPrinter],
                  title: __(T.titles.select_printer),
                  searchable: true,
                }}
              />
              <Box style={{ flex: 1 }}>
                <FormSchemaTextField
                  defaultValue={undefined}
                  field={{ type: 'text', label: 'Layout', name: 'Layout', focus: true }}
                  index={0}
                  value={printLayout}
                  onChange={(text: string) => {
                    this.setState({
                      printLayout: text,
                    })
                  }}
                />
              </Box>
            </Box>
            <Spacer height={'20'} />
            <Button
              style={{ width: 100, alignSelf: 'center' }}
              variant="default"
              title={__(T.misc.save)}
              onClick={this.saveLayout}
            />
            <Spacer height={'20'} />
          </InfoContainer>
          <Spacer height={'30'} />
          <Box center>
            <AntennaButton
              style={{ width: 400 }}
              hideClear={hideClear}
              showPendingTags={false}
              onClear={() => this.clearReads()}
            />
          </Box>
          <Spacer height={'30'} />
          <InfoContainer>
            <Box row vcenter>
              <Box flex />
              <Box flex center>
                <Button
                  style={{ width: 200 }}
                  variant="secondary"
                  disabled={readItems.length === 0}
                  title={__(T.misc.print)}
                  onClick={() => this.onPrintPress()}
                />
              </Box>
              <Box flex style={{ alignItems: 'flex-end' }}>
                <Counter bgColor="#CCCCCC" count={ignoredItems?.length ?? 0} text={__(T.misc.ignoreds)} />
              </Box>
            </Box>
            <Spacer height={'20'} />
            <Spacer width={20} />
            <Table data={readItems as any} structure={this.tableStructure} />
          </InfoContainer>
        </Page.Content>
        <Modal
          closeOnOverlayClick={!printLoading}
          visible={showPrintModal}
          onClose={() =>
            this.setState({
              showPrintModal: false,
            })
          }
        >
          <Box>
            <Title>{__(T.misc.print)}</Title>
          </Box>
          <Box pt={20}>
            <Spacer height={'20'} />
            <Box row vcenter center>
              <Button
                variant="secondary"
                disabled={printLoading}
                title={__(T.misc.cancel)}
                onClick={() => this.setState({ showPrintModal: false })}
              />
              <Spacer width={10} />
              <Button
                loading={printLoading}
                disabled={!selectedPrinter}
                title={__(T.misc.confirm)}
                onClick={this.print}
              />
            </Box>
          </Box>
        </Modal>
      </Page>
    )
  }
}

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
`

const Title = styled.span`
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  margin-top: 10px;
`

const Description = styled.label`
  font-weight: 500;
  padding: 25px 15px;
  font-size: 21px;
  text-align: center;
  opacity: 0.5;
`
