import { Component } from 'react'
import { TagCounter, AntennaButton, Box } from 'stylewhere/components'
import { DecodedItem } from 'stylewhere/api'
import { T, __ } from 'stylewhere/i18n'

interface Props {
  items?: DecodedItem<string>[]
  detected?: number
  unknown?: number
  clear?: () => void
  decodeRequest?: any
  onItemDecoded?: any
}

export class TagInfoBox extends Component<Props> {
  render() {
    const { detected, clear, unknown, onItemDecoded, decodeRequest } = this.props
    return (
      <Box flex style={{ overflowY: 'auto' }}>
        <TagCounter layout={{ minHeight: 'auto' }} detected={detected || 0} title={__(T.custom.reads_ok)} />
        {unknown !== undefined && (
          <TagCounter
            layout={{ minHeight: 'auto', bgColor: '#FF965B' }}
            detected={unknown || 0}
            title={__(T.custom.sconosciuto)}
          />
        )}
        <AntennaButton
          decodeRequest={decodeRequest}
          onItemDecoded={onItemDecoded}
          showPendingTags={false}
          onClear={clear}
          hideClear={detected === 0}
        />
      </Box>
    )
  }
}
