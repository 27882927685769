import { Component } from 'react'
import { Page, GroupedItemList, TagInfoBox } from 'stylewhere/components'
import { DecodedItem, ItemEvents, TagInfoItem } from 'stylewhere/api'
import { Router, RemoteOperation, RfidReader, OperationReadingProps, OperationReadingProvider } from 'stylewhere/shared'
import { TagInfoOperationConfig } from 'stylewhere/shared/RemoteOperation'
import { checkItemDecodedStatuses } from 'stylewhere/utils'

interface State {
  readItems: { [epc: string]: DecodedItem<string> }
  items: DecodedItem<string>[]
  unknown: number
  detected: number
}

export default class CountCollo extends Component<OperationReadingProps<State>, State> {
  debounceDecodeTime = 300
  timer?: any
  useSupplier = true
  simulation = false
  locationState = Router.getLocationState<State>(this.props)
  operation = RemoteOperation.getOperationConfig<TagInfoOperationConfig>(Router.getMatchParams(this.props).opCode)
  readTags = {}

  state: State = {
    readItems: {},
    items: [],
    detected: 0,
    unknown: 0,
  }

  componentDidMount() {
    RfidReader.setAutomaticStop(this.operation.autostopAntennaTimeout > 0)
    RfidReader.setAutomaticStopTime(this.operation.autostopAntennaTimeout)
    RfidReader.setBatchInterval(this.operation.batchInterval)
    RfidReader.setBatchIntervalTagCount(this.operation.batchIntervalTagCount)
    RfidReader.setBatchIntervalTime(this.operation.batchIntervalTime)
    RfidReader.setDebounceDecodeTime(this.debounceDecodeTime)
    OperationReadingProvider.init(this.operation, this.locationState, this.goBack, this.setRfidReaderDecode, true)
    this.startTimer()
  }

  componentWillUnmount() {
    this.stopTimer()
    RfidReader.clear()
  }

  getDecodeRequest = () => {
    return {
      url: TagInfoItem.batchValidateEndpoint(),
      payload: {
        operationId: this.operation.id,
        fetchReplaceIdentifier: false,
      },
    }
  }

  setRfidReaderDecode = () => {
    RfidReader.setOnDecodedItemCallback(this.onItemDecoded, this.getDecodeRequest())
  }

  onItemDecoded = (decodedItems: { [epc: string]: DecodedItem<string> }, tags) => {
    RfidReader.clear()
    if (this.simulation) {
      this.setState({ readItems: decodedItems }, this.processDecodedItem)
    } else {
      this.setState({ readItems: decodedItems })
    }
  }

  startTimer = () => {
    if (!this.simulation) {
      let timeInterval = this.debounceDecodeTime + 700
      if (this.operation.batchInterval) {
        timeInterval = parseInt(this.operation.batchIntervalTime + '', 10) + 500
      }
      this.timer = setInterval(() => {
        this.processDecodedItem()
      }, timeInterval)
    }
  }

  getItemSupplier = async (id) => {
    let supplier = undefined
    let events: any = { content: [] }
    try {
      events = await ItemEvents.get(id, {
        size: 100,
        sort: 'creationDate,DESC',
      })
    } catch (e) {}
    if (events && events.content) {
      const event = events.content.find((ev) => ev.eventType === 'ITEM_CREATED')
      if (event && event.user && event.user.userPlaces && event.user.userPlaces.length > 0) {
        supplier = event.user.userPlaces[0].description
      }
    }
    return supplier
  }

  processDecodedItem = async () => {
    const { readItems, items, unknown } = this.state
    const tmpItems: DecodedItem<string>[] = []
    let unknownDecoded = 0
    let itemsDetected = 0
    const keys = Object.keys(readItems)
    if (keys.length > 0) {
      let tmpItem, check
      keys.map((key) => {
        const decodedItem = readItems[key]
        if (decodedItem && decodedItem !== null) {
          const statusesOk = checkItemDecodedStatuses(decodedItem.statuses || [], ['OK', 'SGTIN_FALLBACK_ITEM_CREATED'])
          if (!decodedItem.item || decodedItem.item === null) {
            check = false
            tmpItem = decodedItem as any
            tmpItem.item = {}
            tmpItem.item.status = 'ignored'
          } else {
            if (!statusesOk) {
              tmpItem = decodedItem as any
              tmpItem.item = {}
              tmpItem.item.status = 'ignored'
            } else {
              tmpItem = (decodedItem.item || decodedItem) as any
              check = tmpItems.find((itm) => itm.item !== null && itm.item?.id === tmpItem.id)
            }
          }
          if (!check) {
            tmpItems.push({
              item: tmpItem,
              identifierCode: key,
            })
            if (decodedItem.item && decodedItem.item.id && statusesOk) {
              itemsDetected++
            }
          }
          if (
            !decodedItem.item ||
            decodedItem.item === null ||
            !statusesOk ||
            (decodedItem.item && !decodedItem.item.id)
          ) {
            unknownDecoded++
          }
        } else {
          unknownDecoded++
        }
      })

      //si prendono i itemEvents per determinare il supplier dell'item
      if (this.useSupplier) {
        await Promise.all(
          tmpItems.map(async (itm) => {
            if (itm.item && itm.item.id) {
              itm.item.supplier = await this.getItemSupplier(itm.item.id)
            }
          })
        )
      }
      this.setState({ items: tmpItems, readItems: {}, unknown: unknownDecoded, detected: itemsDetected })
    } else if (items.length > 0 || itemsDetected > 0 || unknown > 0) {
      this.setState({ items: tmpItems, readItems: {}, unknown: unknownDecoded, detected: itemsDetected })
    }
  }

  stopTimer = () => {
    if (!this.timer) return
    clearInterval(this.timer)
    this.timer = undefined
  }

  clear = () => {
    this.readTags = {}
    this.setState({ readItems: {}, items: [], unknown: 0 })
  }

  goBack = () => {
    Router.navigate('/')
  }

  render() {
    const { items, unknown, detected } = this.state
    return (
      <Page title={this.operation.description} enableEmulation>
        <Page.Sidebar>
          <TagInfoBox
            decodeRequest={this.getDecodeRequest()}
            onItemDecoded={this.onItemDecoded}
            items={items}
            detected={detected}
            unknown={unknown}
          />
        </Page.Sidebar>
        <Page.Content notBoxed>
          <GroupedItemList
            configuration={{ showSupplier: this.useSupplier, showProductImage: false, hideQuantity: true }}
            rows={items}
          />
        </Page.Content>
      </Page>
    )
  }
}
