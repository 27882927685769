import { Component } from 'react'
import styled from '@emotion/styled'
import { Spinner } from '@chakra-ui/react'
import { motion, MotionStyle } from 'framer-motion'
import { Icons, Box, Button, ItemInfoModal, StatusLabel, StatusChip, Image } from 'stylewhere/components'
import { Products, TmrItem, GroupedItemRowProps } from 'stylewhere/api'
import { closeModal, openModal, isQcStatusProduct } from 'stylewhere/shared/utils'
import { T, __ } from 'stylewhere/i18n'
import { AppStore } from 'stylewhere/shared'

interface State {
  modalVisible: boolean
}

export class GroupedItemRow extends Component<GroupedItemRowProps, State> {
  static Skeleton = styled(Box)``

  roundedLabel = (label: string, value?: string, style?: MotionStyle) => (
    <Box
      row
      mr={15}
      style={{
        backgroundColor: '#EDEDED',
        borderRadius: 5,
        ...style,
      }}
      pv={5}
      ph={10}
    >
      <SmallLabel>{label.toUpperCase()}</SmallLabel>
      <SmallValue>{value ?? ' ---'}</SmallValue>
    </Box>
  )

  getUnknownItemRowCode = () => {
    const { decodedItem } = this.props
    if (decodedItem.identifierCode) return decodedItem.identifierCode
    if (decodedItem.epc) return decodedItem.epc
    return 'Identifier N/A'
  }

  renderUnknownItemRow = () => {
    const { decodedItem, onRemoveItem, unknownHeight, mb } = this.props
    return (
      <Row row mb={mb} height={unknownHeight || 66} bkg="#FF965B" center>
        <Box flex ph={16} pl={0}>
          <ReadIdentifierCode>{this.getUnknownItemRowCode()}</ReadIdentifierCode>
        </Box>
        {!!onRemoveItem && (
          <Box m={8}>
            <Button onClick={() => onRemoveItem(decodedItem)} variant="secondary" size="small" circle>
              <Icons.Delete width={24} height={24} />
            </Button>
          </Box>
        )}
      </Row>
    )
  }

  showItemModal = () => {
    const { decodedItem } = this.props
    if (decodedItem.item && !decodedItem.item.fakeItemID) {
      openModal({
        id: 'item-modal',
        modal: <ItemInfoModal visible itemId={decodedItem.item.id} onClose={() => closeModal('item-modal')} />,
      })
    }
  }

  isWarningZone = () => {
    const { operation, itemZoneBase, decodedItem } = this.props
    if (operation && operation.enabledCheckItemZone && itemZoneBase && decodedItem.item && decodedItem.item.zone) {
      return decodedItem.item.zone.id !== itemZoneBase
    }
    return false
  }

  getCode = () => {
    const { decodedItem, configuration } = this.props
    if (decodedItem && decodedItem.item) {
      const { product } = decodedItem.item! as TmrItem
      const productCode = product && product.code ? product.code : 'N/A'
      if (configuration && configuration.useProductCode) {
        return productCode
      }
      if (decodedItem.item.attributes && decodedItem.item.attributes.wam) {
        let wam = decodedItem.item.attributes.wam.value || decodedItem.item.attributes.wam
        if (wam) {
          if (wam.length > 5) {
            wam = wam.substr(0, wam.length - 5) + ' ' + wam.substr(wam.length - 5, 5)
          }
          return wam
        }
        return productCode
      } else {
        return productCode
      }
    }
    return 'N/A'
  }

  renderItemRow = () => {
    const { decodedItem, mb, configuration, hideProductInfo, operation, disabledCounter } = this.props
    const { product } = decodedItem.item! as TmrItem
    const cqStatus = isQcStatusProduct(decodedItem.item)
    const productSize = (product && product.size && product.size.value) || 'N/A'
    const productColor = (product && product.color && product.color.value) || 'N/A'
    const productMaterial = (product && product.material && product.material.value) || 'N/A'
    const productSizeColor =
      product &&
      product.attributes &&
      product.attributes.tagliaColore &&
      product.attributes.tagliaColore.value &&
      product.attributes.tagliaColore.value !== ''
        ? product.attributes.tagliaColore.value
        : 'N/A'

    const showDescription = product && product.description && product.description !== ''
    const quantity = -1
    const viewCqStatus = !configuration || (configuration && !configuration.hideCqStatus)
    const viewQuantity = (!configuration || (configuration && !configuration.hideQuantity)) && quantity >= 0
    const viewImage = configuration && configuration.showProductImage && product && product.code
    const supplier = decodedItem && decodedItem.item && decodedItem.item.supplier
    const hasCqStatus = decodedItem.item && decodedItem.item.attributes && decodedItem.item.attributes.qcStatus

    const read = decodedItem.detected ?? 0 // + (unexpected ?? 0)
    const hasChecklist = operation && 'hasChecklist' in operation && operation.hasChecklist
    const counterValue = decodedItem.expected ? `${read}/${decodedItem.expected}` : `${read}${hasChecklist ? '/0' : ''}`
    return (
      <Row row mb={mb} onClick={this.showItemModal}>
        {viewImage && AppStore.getShowProductImage() && (
          <Box center mr={15}>
            <Image src={Products.getImageUrl(product)} width={100} height={100} />
          </Box>
        )}
        <Box width={viewQuantity || viewCqStatus ? '25%' : viewImage ? '30%' : '40%'} mr={15} ml={0}>
          <Box vcenter flex>
            <ReadIdentifierCode>{this.getCode()}</ReadIdentifierCode>
            {showDescription && !hideProductInfo && <DescriptionLabel>{product.description}</DescriptionLabel>}
            {this.isWarningZone() && (
              <StatusChip mt={8} customStatusType="warning" status={__(T.misc.warning_zone_items)} />
            )}
          </Box>
        </Box>
        <Box flex ml={15} mr={viewQuantity || viewCqStatus ? 15 : 0} vcenter row>
          {product ? (
            <ChipsProductBox flex row vcenter justify={viewQuantity || viewCqStatus ? 'flex-start' : 'flex-end'}>
              <ChipsProduct>
                <ChipsProductLabel>{__(T.misc.material) + ':'}</ChipsProductLabel>
                <ChipsProductValue>{productMaterial}</ChipsProductValue>
              </ChipsProduct>
              {productSizeColor ? (
                <ChipsProduct>
                  <ChipsProductLabel>{__(T.misc.size) + ' - ' + __(T.misc.color) + ':'}</ChipsProductLabel>
                  <ChipsProductValue>{productSizeColor}</ChipsProductValue>
                </ChipsProduct>
              ) : (
                <>
                  <ChipsProduct>
                    <ChipsProductLabel>{__(T.misc.size) + ':'}</ChipsProductLabel>
                    <ChipsProductValue>{productSize}</ChipsProductValue>
                  </ChipsProduct>
                  <ChipsProduct>
                    <ChipsProductLabel>{__(T.misc.color) + ':'}</ChipsProductLabel>
                    <ChipsProductValue>{productColor}</ChipsProductValue>
                  </ChipsProduct>
                </>
              )}
              {configuration && configuration.showSupplier && supplier && (
                <ChipsProduct>
                  <ChipsProductLabel>{__(T.report_tbox.supplier) + ':'}</ChipsProductLabel>
                  <ChipsProductValue>{supplier}</ChipsProductValue>
                </ChipsProduct>
              )}
            </ChipsProductBox>
          ) : (
            <ChipsProductBox flex row vcenter justify={viewQuantity || viewCqStatus ? 'flex-start' : 'flex-end'}>
              <ChipsProduct>
                <ChipsProductLabel>{__(T.custom.prodottoSconosciuto)}</ChipsProductLabel>
              </ChipsProduct>
            </ChipsProductBox>
          )}
        </Box>
        {viewQuantity && (
          <Box center mr={viewCqStatus ? 15 : 0}>
            <Quantity status={cqStatus}>
              <QuantityValue>{quantity}</QuantityValue>
            </Quantity>
          </Box>
        )}
        {viewCqStatus && (
          <Box width={75} center>
            {hasCqStatus && hasCqStatus !== 'LOADING' && (
              <StatusLabel text={'CQ ' + (cqStatus ? 'OK' : 'KO')} status={cqStatus ? 'received' : 'in_inbound'} />
            )}
            {hasCqStatus && hasCqStatus === 'LOADING' && <Spinner thickness={'5px'} speed={'0.65s'} color={'#000'} />}
          </Box>
        )}
        {hasChecklist && !disabledCounter && (
          <Box center ml={15}>
            <SmallCounter
              mr={0}
              ph={20}
              detected={read}
              expected={decodedItem.expected ?? 0}
              unexpected={decodedItem.unexpected ?? 0}
              noChecklist={!hasChecklist}
            >
              {counterValue}
            </SmallCounter>
          </Box>
        )}
      </Row>
    )
  }

  render() {
    const { decodedItem } = this.props
    if (!decodedItem.item?.id) return this.renderUnknownItemRow()

    return this.renderItemRow()
  }
}

const Row = styled(Box)<{ mb?; bkg?: string }>`
  margin-bottom: ${({ mb }) => (mb ? mb + 'px' : '20px')};
  border: 2px solid #edecec;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  background-color: ${({ theme, bkg }) => (bkg ? bkg : theme.background2)};
  padding: 10px 15px;
`

const ReadIdentifierCode = styled.p`
  font-weight: bold;
  font-size: 16pt;
  line-height: 18pt;
`
const SmallValue = styled.p`
  font-weight: bold;
  font-size: 12pt;
`
const SmallLabel = styled.p`
  font-size: 12pt;
  margin-right: 5px;
`

const DescriptionLabel = styled.span`
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  margin-top: 3px;
`

const ChipsProductBox = styled(Box)`
  flex-wrap: wrap;
`

const ChipsProduct = styled.div`
  padding: 0 6px;
  border-radius: 5px;
  text-align: center;
  background-color: #ededed;
  margin-right: 3px;
  margin-top: 3px;
`

const ChipsProductLabel = styled.span`
  font-size: 16px;
`

const ChipsProductValue = styled.span`
  font-size: 16px;
  font-weight: bold;
  margin-left: 5px;
`

const Quantity = styled(motion.div)<{ status }>`
  border-radius: 5px;
  background-color: ${({ status }) => (status ? '#74f1a9' : '#FBBF66')};
  font-weight: bold;
  font-size: 16px;
  height: 42px;
  padding: 0px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const QuantityValue = styled.span`
  font-size: 30px;
  font-weight: 900;
`

const SmallCounter = styled(Box)<{
  detected?: number
  expected?: number
  unexpected?: number
  noChecklist?: boolean
}>`
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  min-width: 100px;
  width: 100%;
  align-self: center;
  background-color: ${({ detected = 0, expected = 0, unexpected = 0, noChecklist = false }) => {
    if (noChecklist) return '#F0F0F0'
    switch (status) {
      case 'error':
        return '#FF8474'
      case 'warning':
        return '#FFEA67'
      case 'ignored':
        return '#DAE6ED'
      default:
        if (detected + unexpected > expected) return '#F2B57D'
        if (noChecklist) return '#75EBA8'
        if (unexpected > 0) return '#F2B57D'
        if (detected < expected) return '#EDEDED'
        if (detected === expected) return '#75EBA8'
        return '#F0F0F0'
    }
  }};
  border-radius: 10px;
`
