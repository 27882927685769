import { merge as _merge } from 'lodash'
import en from 'core/translations/en'

export default _merge(en, {
  titles: {
    outbound_bthp: 'Outbound BTHP Certificate',
  },
  messages: {
    are_you_sure_to_clear_reads: 'Are you sure to clear reads?',
    are_you_sure_to_confirm: 'Are you sure to confirm?',
    inbound_conveyor_confirm_missing_message: 'There are still -missing- items to confirm, are you sure to confirm?',
  },
  simple_parcel_list: {
    to_read: 'To Read',
    ok: 'Ok',
    force: 'Force',
    force_parcel: 'Force parcel {{code}}',
    force_parcel_desc: 'Insert user PIN to force parcel',
    forced: 'Forced',
    unexpected: 'Unexpected',
    remove: 'Remove',
    invalid_pin: 'User PIN invalid',
  },
  create_parcel_gate: {
    width: 'Width',
    height: 'Height',
    depth: 'Depth',
    tare: 'Tare',
    description: 'Description',
  },
  misc: {
    sorted_quantity: 'Sorted qty',
    sorted_date: 'Sorted date',
    error: 'Error',
    OK_WORKER: 'OK WORKER',
    KO_WORKER: 'KO WORKER',
    OK_REPAIR: 'OK REPAIR',
    RESO_RICHIESTO_CQ: 'QC REQUESTED',
    RESO_OK: 'OK RETURN',
    OK_VALDAGNO: 'OK VALDAGNO',
    KO_VALDAGNO: 'KO VALDAGNO',
    SECOND_VALDAGNO: 'SECOND CHOICE',
    OK_LINING: 'OK LINING',
    ok_first_inbound_status: 'OK FIRST INBOUND',
    OK_RISC: 'RISK',
    KO_LINING: 'KO LINING',
    size_color: 'Size/Color',
    select_size_color: 'Select size/color',
    print: 'Print',
    ok: 'OK',
    missings: 'Missings',
    embedded: 'Embedded',
    ignoreds: 'Ignoreds',
    select_printer: 'Select printer',
    printing_started: 'Printing started',
    release_reports: 'Release reports',
    quality_checks: 'Quality checks',
    quality_check: 'Quality check',
    total_mission_qty: 'Qta totale missione',
    inbound_read_qty: 'Qta letta ingresso varco',
    checked_qty: 'Qta controlli effettuati',
    checked: 'Collaudati',
    returned: 'Rientrati',
    damaged: 'Difetti',
    qty_damaged_first_check: 'Qta difettati 1° controllo',
    first_check_date: 'Data 1° controllo',
    qty_damaged_second_check: 'Qta difettati 2° controllo',
    second_check_date: 'Data 2° controllo',
    note: 'Note',
  },
  serverError: {
    WRONG_ITEM_SUPPLIER: 'Some read articles cannot be taken over by the supplier.',
    QUANTITY_MISMATCH_ERROR: 'Quantities entered do not correspond to the quantities detected.',
    ZONE_MISMATCH_ERROR: 'Zone entered does not correspond to the zone detected.',
  },
  custom: {
    barcode: 'Barcode BC',
    barcodeBCTrim: 'Barcode BC trim',
    barcodeJDE: 'Barcode JDE',
    barcodeROQ: 'Barcode ROQ',
    barcodeROQTrim: 'Barcode ROQ trim',
    class: 'Class',
    department: 'Department',
    dimensionSystem: 'Custom class',
    ean: 'EAN',
    eanIdPadre: 'Father EAN',
    itemId: 'Item Id',
    stagioneROQ: 'ROQ season',
    subclass: 'Subclass',
    subdepartment: 'Subdepartment',
    variantType: 'Typology ',
    rfidEnabled: 'RFID enabled',
    stato: 'Status',
    ricondizionamentoFornitore: 'Recondition supplier',
    codiceShelf: 'Stock Compartment',
    montante: 'Section',
    ripiano: 'Bin',
    scaffale: 'Bay',
    sconosciuto: 'UNKNOWN',
    prodottoSconosciuto: 'Unknown product',
    countTBoxParcel: 'Umg',
    countTBoxDDT: 'Mission',
    reads_ok: 'READS OK',
    WAM: 'WAM',
    total_list: 'Total mission',
    total_parcel: 'Total umg',
    order_number: 'Order number',
    addon: 'addonCode',
    model: 'Modello',
    sizeCode: 'Size code',
    sizeDescription: 'Size description',
    serial: 'Serial',
    identifier: 'Serial',
    product: 'Barcode BC',
    shift: 'Shift',
    destination: 'Destination',
    regDate: 'Date',
    listPosition: 'List position',
    list: 'List',
    customerName: 'Customer Name',
    regTime: 'Time',
    event: 'Event',
    customer: 'Customer',
    isCarryover: 'Carryover',
    carryoverValue: 'Carryover',
    madeIn: 'Made in',
    collectionCode: 'Collection',
    collectionDescription: 'Collection Description',
    collectionYear: 'Year',
    fatherProductValue: 'Father Product',
    genderValue: 'Gender',
    genderValueDescription: 'Gender Description',
    line: 'Brandline',
    lineDescription: 'Line Description',
    marchioDescription: 'Marchio Description',
    marchioValue: 'Marchio',
    merchandiseClassCode: 'Category',
    merchandiseClassDescription: 'Category Description',
    merchandiseSubclassCode: 'Sub-Category',
    merchandiseSubclassDescription: 'Sub-Category Description',
    modelPartColor: 'Model Part Color',
    modelPartColorSizeValue: 'Model Part Color Size',
    isPermanent: 'Permanent',
    permanentValue: 'Permanent',
    isSeasonal: 'Seasonal',
    seasonalValue: 'Seasonal',
    sleeveTypeValue: 'Sleeve Type',
    rejected: 'Rejected',
    tot_to_check: 'Total to check',
  },
  attributes: {
    material: 'Material',
    workerCode: 'Worker Code',
    workerExternalCode: 'Worker External Code',
    workerIdentifier: 'Worker Identifier',
    workerDescription: 'Worker Description',
    workerType: 'Worker Type',
    orderCode: 'Order Code',
    sapQualityControlStatus: 'SAP Quality Control Status',
    quantityOkWorker: 'OK WORKER QTY',
    quantityKoWorker: 'KO WORKER QTY',
    quantityOkRepair: 'OK REPAIR QTY',
    quantityOkValdagno: 'OK VALDAGNO QTY',
    quantityOkFirstInbound: 'OK FIRST INBOUND QTY',
    quantityOkRis: 'RISK QTY',
    quantityKoValdagno: 'KO VALDAGNO QTY',
    quantitySecondValdagno: 'SECOND CHOICE QTY',
    quantityOkLining: 'OK LINING QTY',
    quantityKoLining: 'KO LINING QTY',
  },
  fields: {
    mission: 'Mission',
    mission_type: 'Mission type',
    supplier: 'Supplier',
    physical_storage: 'Physical storage',
    season: 'Season',
    ddt: 'DDT',
    notification: 'Notification',
    ddt_date: 'DDT Date',
    notification_date: 'Notification Date',
    product_category: 'Product category',
  },
})
